import { v4 as uuidv4 } from 'uuid'
import fetch from 'cross-fetch'

class ApiAsync {
  setConfig (config) {
    this.config = config
  }

  setDispatch (dispatch) {
    this.dispatch = dispatch
  }

  setAuthToken (authToken) {
    this.authToken = authToken
  }

  fetchWithToken (params, requestUuid) {
    let urlApiMS = this.config[params.endpoint]

    if (params.path) {
      urlApiMS = urlApiMS + params.path
    }

    if (undefined === params.showLoading) {
      params.showLoading = true
    }

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
      // 'Accept-Encoding': 'gzip, compress, br'
    }

    if (this.authToken) {
      headers.AUTH_TOKEN = this.authToken
    }

    if (params.showLoading) {
      this.dispatch({
        type: 'api/REQUESTED',
        data: {
          requestUuid: requestUuid
        }
      })
    }
    return fetch(urlApiMS, {
      method: params.method || 'GET',
      headers: headers,
      body: JSON.stringify(params.obj)
    })
  }

  requestSync (params) {
    const obj = this
    const requestUuid = uuidv4()
    return obj.fetchWithToken(params, requestUuid).then((response) => {
      if (params.showLoading) {
        obj.dispatch({
          type: 'api/RECEIVED',
          data: {
            requestUuid: requestUuid
          }
        })
      }
      return response.json()
    })
  }
}

export default new ApiAsync()
